<template>
  <b-row class="match-height">
    <b-col lg="12">
        <b-card-code title="معلومات المنتسب">
        <div class="demo-vertical-spacing">
      <b-progress
      v-if="precentage !== 0"
        :value="precentage"
        max="100"
      />
      </div>
          <form @submit.prevent="validationForm" enctype="multipart/form-data">
            <b-tabs>
      <validation-observer ref="simpleRules">

              <b-tab active>
                <template #title>
                  <feather-icon icon="HomeIcon" />
                  <span>معلومات الاكاديمية </span>
                </template>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="الاسم باللغة العربية"
                      label-for="mc-first-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="First Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.ar_name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="الاسم مع اللقب العلمي باللغة العربية"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="الاسم باللغة الانكليزية"
                      label-for="mc-last-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.en_name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="الاسم مع اللقب العلمي باللغة الانكليزية"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="نبذة باللغة العربية"
                      label-for="mc-last-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="form.ar_description"
                          :state="errors.length > 0 ? false : null"
                          placeholder="نبذة عن السيرة الذاتية باللغة العربية"
                        rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="نبذة باللغة الانكليزية"
                      label-for="mc-last-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="form.en_description"
                          :state="errors.length > 0 ? false : null"
                          placeholder="نبذة عن السيرة الذاتية باللغة الانكليزية"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="الاختصاص العام باللغة العربية"
                      label-for="mc-last-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.ar_general_specialty"
                          :state="errors.length > 0 ? false : null"
                          placeholder="الاختصاص العام باللغة العربية"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="الاختصاص العام باللغة الانكليزية"
                      label-for="mc-last-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.en_general_specialty"
                          :state="errors.length > 0 ? false : null"
                          placeholder="الاختصاص العام باللغة الانكليزية"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="الاختصاص الدقيق باللغة العربية"
                      label-for="mc-last-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.ar_accurate_specialty"
                          :state="errors.length > 0 ? false : null"
                          placeholder="الاختصاص الدقيق باللغة العربية"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6">
                    <b-form-group
                      label="الاختصاص الدقيق باللغة الانكليزية"
                      label-for="mc-last-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.en_accurate_specialty"
                          :state="errors.length > 0 ? false : null"
                          placeholder="الاختصاص الدقيق باللغة الانكليزية"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="الشهادة العلمية باللغة العربية"
                      label-for="mc-last-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.ar_graduation_degree"
                          :state="errors.length > 0 ? false : null"
                          placeholder="الشهادة العلمية باللغة العربية"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="الشهادة العلمية باللغة الانكليزية"
                      label-for="mc-last-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="form.en_graduation_degree"
                          :state="errors.length > 0 ? false : null"
                          placeholder="الشهادة العلمية باللغة الانكليزية"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      حفظ البيانات
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>
              </validation-observer>
              <b-tab>
                <template #title>
                  <feather-icon icon="ToolIcon" />
                  <span>الحسابات</span>
                </template>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="كوكل سكولار"
                      label-for="mc-first-name"
                    >
                      <b-form-input
                        id="mc-first-name"
                        v-model="form.google_scholar"
                        placeholder="كوكل سكولار"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="الحساب الاكاديمي للكلية"
                      label-for="mc-last-name"
                    >
                      <b-form-input
                        id="mc-last-name"
                        v-model="form.email"
                        placeholder="الحساب الاكاديمي للكلية"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="سكوباس" label-for="mc-city">
                      <b-form-input
                        id="mc-city"
                        v-model="form.scopus"
                        placeholder="سكوباس"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="بابلون" label-for="mc-country">
                      <b-form-input
                        id="mc-country"
                        v-model="form.publon"
                        placeholder="بابلون"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="رسيرج كايت" label-for="mc-company">
                      <div class="form-label-group">
                        <b-form-input
                          id="mc-company"
                          v-model="form.research_gate"
                          placeholder="رسيرج كايت"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="ORCID" label-for="mc-country">
                      <b-form-input
                        id="mc-country"
                        v-model="form.orcid"
                        placeholder="ORCID"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="اكاديميا" label-for="mc-company">
                      <div class="form-label-group">
                        <b-form-input
                          id="mc-company"
                          v-model="form.academia"
                          placeholder="اكاديميا"
                        />
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col md="6"> </b-col>
                  <b-col> </b-col>
                </b-row>
              </b-tab>
              <b-tab>
                <template #title>
                  <feather-icon icon="ToolIcon" />
                  <span>الملفات</span>
                </template>
                  <b-row>
                    <b-col md="4">
                      <b-container fluid class="p-1 bg-dark">
                        <b-row>
                          <b-col>
                            <b-img
                              thumbnail
                              center
                              :src="getImage(form.image)"
                              alt="Image 1"
                            />
                          </b-col>
                        </b-row>
                      </b-container>
                    </b-col>
                    <b-col md="4">
                      <b-card-text>
                        <span>اسقط صورة لتغيير الصورة  </span>
                      </b-card-text>
                      <b-form-file
                      @change="selectImage"
                        v-model="form.image"
                        type="file"
                        placeholder="اختر صورة او اسقطها هنا..."
                        drop-placeholder="اختر صورة او اسقطها هنا..."
                      />
                      <b-card-text>
                        <span v-if="form.file_cv"
                          >اسقط ملف السيرة الذاتية (pdf.) باللغة العربية , علما ان
                          <a
                            href="#"
                            @click="cv(form.file_cv)"
                            v-if="form.file_cv"
                            >اضغط هنا</a
                          >
                          لعرض السيرة الذاتية 
                        </span>
                        <span v-else
                          >اسقط ملف السيرة الذاتية (pdf.) باللغة العربية  
                        </span>
                      </b-card-text>
                      <b-form-file
                        ref="ArCV"
                        v-model="form.file_cv"
                        type="file"
                        @change="selectArCV"
                        placeholder="اختر الملف او اسقطها هنا..."
                        drop-placeholder="اختر الملف او اسقطها هنا..."
                      />
                      <b-card-text>
                        <span v-if="form.en_file_cv"
                          >اسقط ملف السيرة الذاتية (pdf.) باللغة الانكليزية هنا للتغيير   , علما انه يمكن عرض السيرة الدراسية 
                          <a
                            href="#"
                            @click="cv(form.en_file_cv)"
                            v-if="form.en_file_cv"
                            >اضغط هنا</a
                          >
                          
                        </span>
                        <span v-else>اسقط السيرة الذاتية (pdf.) باللغة الانكليزية</span>
                      </b-card-text>
                      <b-form-file
                        v-model="form.en_file_cv"
                        type="file"
                        placeholder="اختر الملف او اسقطها هنا..."
                        drop-placeholder="اختر الملف او اسقطها هنا..."
                        @change="selectEnCV"
                      />
                    </b-col>
                  </b-row>
              </b-tab>
            </b-tabs>
          </form>
        </b-card-code>
      
    </b-col>
  </b-row>
</template>

<script>
import Ripple from "vue-ripple-directive";
import BCardCode from "@core/components/b-card-code";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { filesUrl } from "@/main.js";
import {
  BTabs,
  BTab,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,BFormTextarea,
  BForm,
  BButton,
  BImg,
  BContainer,BProgress,
  BFormFile,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,BProgress,
    BCardCode,
    BTabs,
    BTab,
    BCardText,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BButton,BFormTextarea,
    Ripple,
    BImg,
    BContainer,
    BFormFile,
  },
  data() {
    return {
      isManager:0,
      required,
      precentage:0,
      // changedImage:null,
      // changedArCV:null,
      // changedEnCV:null,
      notifi: {
        route: "",
        title: "",
        item_id: "",
        table: "",
        users: [],
        departments: [],
        role_id: "",
        type: "",
        subtitle: "",
        bg: "",
      },
      form: {
        ar_name: null,
        en_name: null,
        ar_description: null,
        en_description: null,
        email: null,
        image: null,
        ar_graduation_degree: null,
        en_graduation_degree: null,
        ar_general_specialty: null,
        en_general_specialty: null,
        ar_accurate_specialty: null,
        en_accurate_specialty: null,
        file_cv: null,
        en_file_cv: null,
        google_scholar: null,
        scopus: null,
        publon: null,
        research_gate: null,
        orcid: null,
        academia: null,
        teacher_id: null,
      },
    };
  },
  watch:{
"$route.params.id": {
      handler: function (search) {
        this.gS();
      },
    },
  },
  mounted() {
    this.getTeacher();
  },
  methods: {
    async getTeacher() {
      await this.axios.get(`users/me?show=${this.$route.params.id}`).then((res) => {
        this.form = res.data.teacher;
        this.form.teacher_id = res.data.teacher_id;
      });
    },
    selectImage(e){
      this.image=null
      this.image=e.target.files[0]
    },
    selectArCV(e){
      this.file_cv=null
      this.file_cv=e.target.files[0]
      console.log(this.file_cv)
    },
    async sendNoti() {
      await this.axios.post(`notifications`, this.notifi);
    },
    selectEnCV(e){
      this.en_file_cv=null
      this.en_file_cv=e.target.files[0]
      console.log(this.en_file_cv)
    },
    async updateData() {
      // console.log(this.form.en_file_cv)
      let data = new FormData();
      data.append("ar_name", this.form.ar_name);
      data.append("en_name", this.form.en_name);
      data.append("ar_description", this.form.ar_description);
      data.append("en_description", this.form.en_description);
      data.append("ar_graduation_degree", this.form.ar_graduation_degree);
      data.append("ar_general_specialty", this.form.ar_general_specialty);
      data.append("ar_accurate_specialty", this.form.ar_accurate_specialty);
      data.append("en_graduation_degree", this.form.en_graduation_degree);
      data.append("en_general_specialty", this.form.en_general_specialty);
      data.append("en_accurate_specialty", this.form.en_accurate_specialty);
      data.append("email", this.form.email);
      data.append("google_scholar", this.form.google_scholar);
      data.append("scopus", this.form.scopus);
      data.append("publon", this.form.publon);
      data.append("research_gate", this.form.research_gate);
      data.append("orcid", this.form.orcid);
      data.append("academia", this.form.academia);
      data.append("department_id", this.form.department_id);
      data.append("is_manager", this.isManager);
      if (this.image && this.image !== "") {
      data.append("image", this.image);
      }
      if (this.file_cv && this.file_cv !== "") {
      data.append("file_cv", this.file_cv);
      }
      if (this.en_file_cv && this.en_file_cv !== "") {
      data.append("en_file_cv", this.form.en_file_cv);
      }

      let headers = { 
        headers:{"content-type": 'multipart/form-data'}
       };
      await this.axios
        .post(`teacher/${this.form.teacher_id}`, data,{
          onUploadProgress: progressEvent => {
            this.precentage =
              (progressEvent.loaded / progressEvent.total) * 100;
          }
        }, headers )
        .then((res) => {
          this.getTeacher();
          this.makeToast(
            "success",
            "update Success",
            "Your update is done successfully"
          );
            this.notifi.route = "profile-info-notif";
            this.notifi.title = "update action";
            this.notifi.item_id = res.data.id;
            this.notifi.table = "profile";
            this.notifi.role_id = 1;
            this.notifi.type = "light-worning";
            this.notifi.subtitle = "updated his profile";
            this.notifi.bg = "bg-worning";
            this.sendNoti();
this.precentage=0
        })
        .catch((error) => {
          this.makeToast(
            "danger",
            "Update failed",
            "Your update faild is try again or contact with IT Support ("
          );
        });
    },
    getImage(path) {
      return `${filesUrl}/${path}`;
    },
    cv(path) {
      window.open(`${filesUrl}/${path}`, "_blank");
    },
    makeToast(variant = null, title = null, body = null) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: false,
      });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.updateData();
        } else {
          this.makeToast(
            "danger",
            "field required",
            "there is a feilds you have fill before submit !"
          );
        }
      });
    },
  },
};
</script>
